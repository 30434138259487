import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import chunk from 'lodash/chunk';
import { FormattedMessage } from '../../../../../util/reactIntl';
import { ExternalLink, NamedLink, IconArrowHead, H4, IconAdd } from '../../../../../components';
import css from './PriorityLinks.module.css';

import womenImg1 from '../../../../../assets/images/categories/women/1.jpg';
import menImg1 from '../../../../../assets/images/categories/men/1.jpg';
import kidsImg1 from '../../../../../assets/images/categories/kids/1.jpg';
import accessoriesImg1 from '../../../../../assets/images/categories/accessories/1.jpg';
import designerImg1 from '../../../../../assets/images/categories/designer/1.jpg';
import weddingImg1 from '../../../../../assets/images/categories/wedding/1.jpg';

const SUB_MENU_KEYS = ['women', 'men', 'kids', 'accessories'];

const IMAGES = {
  women: [womenImg1],
  men: [menImg1],
  kids: [kidsImg1],
  accessories: [accessoriesImg1],
  designer: [designerImg1],
  wedding: [weddingImg1],
};

/**
 * Create component that shows only a single "Post a new listing" link.
 *
 * @param {*} props contains customLinksMenuClass
 * @returns div with only one link inside.
 */
export const CreateListingMenuLink = props => {
  return (
    <div className={props.customLinksMenuClass}>
      <NamedLink name="NewListingPage" className={classNames(css.priorityLink, css.highlight)}>
        <span className={css.priorityLinkLabel}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    </div>
  );
};

/**
 * Link component that can be used on TopbarDesktop.
 *
 * @param {*} props containing linkConfig including resolved 'route' params for NamedLink.
 * @returns NamedLink or ExternalLink component based on config.
 */
const PriorityLink = ({ linkConfig, config, onMenuClick }) => {
  // const arrow = (
  //   <div className={css.arrows}>
  //     <div className={css.arrowBelow} />
  //     <div className={css.arrowTop} />
  //   </div>
  // );
  const { text, type, href, route, highlight } = linkConfig;
  const classes = classNames(css.priorityLink, { [css.highlight]: highlight });
  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    const { name, params, to } = route || {};
    const searchKey = to?.search?.split('=')?.[1];
    const isValidSubMenuKey = !!searchKey && SUB_MENU_KEYS.includes(searchKey);
    const isValidDesignerKey = text === 'Designer' || text === 'designer';
    const isValidWeddingKey = text === 'Wedding';
    if (isValidSubMenuKey) {
      const subMenuConfigs = config?.listing?.listingFields
        .find(f => f.key === 'subCategory')
        ?.enumOptions?.find(e => e.parentKey === searchKey)?.options;
      const subMenuChunks = chunk(subMenuConfigs, 10);
      const numOfChunks = subMenuChunks?.length;
      const listStyle = {
        display: 'grid',
        columnGap: '48px',
        gridTemplateColumns: `repeat(${numOfChunks}, auto)`,
      };
      const mainList = subMenuConfigs.filter(s => !(s?.options?.length > 0));
      const subLists = subMenuConfigs.filter(s => s?.options?.length > 0);
      return (
        <div className={classNames(css.menuContainer, classes)}>
          <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
            <span className={css.priorityLinkLabel}>{text}</span>
          </NamedLink>
          {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
            <div className={css.subMenu}>
              <div className={css.subMenuContent}>
                <div className={css.lists}>
                  {mainList?.length > 0 ? (
                    <div className={css.list}>
                      <NamedLink
                        name={name}
                        params={params}
                        to={to}
                        className={classes}
                        onClick={onMenuClick}
                      >
                        <H4 as="h4" className={css.menuHeader}>
                          {text}
                        </H4>
                      </NamedLink>

                      <ul style={listStyle}>
                        {mainList.map(s => {
                          return (
                            <li>
                              <NamedLink
                                name={name}
                                params={params}
                                to={{
                                  search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                                }}
                                onClick={onMenuClick}
                                className={classes}
                              >
                                <span className={css.priorityLinkLabel}>{s.label}</span>
                              </NamedLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}

                  {subLists.map(s => {
                    return (
                      <div className={css.list}>
                        <NamedLink
                          name={name}
                          params={params}
                          to={{
                            search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                          }}
                          onClick={onMenuClick}
                          className={classes}
                        >
                          <H4 as="h4" className={css.menuHeader}>
                            {s.label}
                          </H4>
                        </NamedLink>

                        <ul style={listStyle}>
                          {s.options.map(ss => {
                            return (
                              <li>
                                <NamedLink
                                  name={name}
                                  params={params}
                                  to={{
                                    search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}&pub_group=${ss.value}`,
                                  }}
                                  onClick={onMenuClick}
                                  className={classes}
                                >
                                  <span className={css.priorityLinkLabel}>{ss.label}</span>
                                </NamedLink>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
                <div className={css.images}>
                  <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
                    <img className={css.image} src={IMAGES[searchKey][0]} />
                  </NamedLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    } else if (isValidDesignerKey) {
      const subMenuConfigs = config?.listing?.listingFields.find(f => f.key === 'designer')
        ?.enumOptions;
      const subMenuChunks = chunk(subMenuConfigs, 10);
      const numOfChunks = subMenuChunks?.length;
      const listStyle = {
        display: 'grid',
        columnGap: '48px',
        gridTemplateColumns: `repeat(${numOfChunks}, auto)`,
      };
      return (
        <div className={classNames(css.menuContainer, classes)}>
          <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
            <span className={css.priorityLinkLabel}>{text}</span>
          </NamedLink>
          {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
            <div className={css.subMenu}>
              <div className={css.subMenuContent}>
                <div className={css.lists}>
                  <div className={css.list}>
                    <NamedLink
                      name={name}
                      params={params}
                      to={to}
                      onClick={onMenuClick}
                      className={classes}
                    >
                      <H4 as="h4" className={css.menuHeader}>
                        {text}
                      </H4>
                    </NamedLink>
                    <ul style={listStyle}>
                      {subMenuConfigs.map(s => {
                        return (
                          <li>
                            <NamedLink
                              name={name}
                              params={params}
                              to={{ search: `?pub_designer=${s.option}` }}
                              onClick={onMenuClick}
                              className={classes}
                            >
                              <span className={css.priorityLinkLabel}>{s.label}</span>
                            </NamedLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className={css.images}>
                  <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
                    <img className={css.image} src={IMAGES['designer'][0]} />
                  </NamedLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );
    } else if (isValidWeddingKey) {
      const listStyle = {
        display: 'grid',
        columnGap: '48px',
        gridTemplateColumns: `repeat(${1}, auto)`,
      };
      return (
        <div className={classNames(css.menuContainer, classes)}>
          <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
            <span className={css.priorityLinkLabel}>{text}</span>
          </NamedLink>
          <div className={css.subMenu}>
            <div className={css.subMenuContent}>
              <div className={css.lists}>
                <div className={css.list}>
                  <NamedLink
                    name={name}
                    params={params}
                    to={{ search: `?pub_bridalGroomOutfit=Yes` }}
                    onClick={onMenuClick}
                    className={classes}
                  >
                    <H4 as="h4" className={css.menuHeader}>
                      {text}
                    </H4>
                  </NamedLink>
                  <ul style={listStyle}>
                    <li>
                      <NamedLink
                        name={name}
                        params={params}
                        to={{ search: `?pub_listingType=men&pub_bridalGroomOutfit=Yes` }}
                        onClick={onMenuClick}
                        className={classes}
                      >
                        <span className={css.priorityLinkLabel}>
                          <FormattedMessage id="TopbarDesktop.groom" />
                        </span>
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink
                        name={name}
                        params={params}
                        to={{ search: `?pub_listingType=women&pub_bridalGroomOutfit=Yes` }}
                        onClick={onMenuClick}
                        className={classes}
                      >
                        <span className={css.priorityLinkLabel}>
                          <FormattedMessage id="TopbarDesktop.bridal" />
                        </span>
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={css.images}>
                <NamedLink name={name} params={params} to={to} onClick={onMenuClick}>
                  <img className={css.image} src={IMAGES['wedding'][0]} />
                </NamedLink>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (route?.name === 'EditListingPage') {
      return (
        <NamedLink name={name} params={params} to={to} className={classes}>
          <span className={css.priorityLinkLabel}>
            <IconAdd className={css.createListingLinkIcon} /> {text}
          </span>
        </NamedLink>
      );
    } else {
      return (
        <NamedLink name={name} params={params} to={to} className={classes}>
          <span className={css.priorityLinkLabel}>{text}</span>
        </NamedLink>
      );
    }
  }
  return (
    <ExternalLink href={href} className={classes}>
      <span className={css.priorityLinkLabel}>{text}</span>
    </ExternalLink>
  );
};

/**
 * Create priority links, which are visible on the desktop layout on the Topbar.
 * If space is limited, this doesn't include anything to the Topbar.
 *
 * @param {*} props contains links array and setLinks function
 * @returns container div with priority links included.
 */
const PriorityLinks = props => {
  const containerRef = useRef(null);

  // With this useEffect, we measure the widths of each rendered priority link
  // This is done once before the real rendering and it's done outside the viewport.
  useEffect(() => {
    const isMeasured = props.links?.[0]?.width;
    if (containerRef.current && !isMeasured) {
      const linksFromRenderedWrapper = [...containerRef.current.childNodes];
      let cumulatedWidth = 0;
      // Generate an array of link configs with width & cumulatedWidth included
      const linksWithWidths = props.links.reduce((links, l, i) => {
        const width = linksFromRenderedWrapper[i].offsetWidth;
        cumulatedWidth = cumulatedWidth + width;
        return [...links, { ...l, width, cumulatedWidth }];
      }, []);
      props.setLinks(linksWithWidths);
    }
  }, [containerRef]);

  const onMenuClick = e => {
    if (typeof window !== 'undefined') {
      e.preventDefault();
      window.location = e.currentTarget.href;
    }
  };

  const { links, priorityLinks, config } = props;
  const isServer = typeof window === 'undefined';
  const isMeasured = links?.[0]?.width && (priorityLinks.length === 0 || priorityLinks?.[0]?.width);
  const styleWrapper = !!isMeasured
    ? {}
    : {
        style: {
          position: 'absolute',
          top: '-2000px',
          left: '-2000px',
          width: '100%',
          height: 'var(--topbarHeightDesktop)',
          display: 'flex',
          flexDirection: 'row',
        },
      };
  const linkConfigs = isMeasured ? priorityLinks : links;

  return isMeasured || isServer ? (
    <div className={css.priorityLinkWrapper} {...styleWrapper} ref={containerRef}>
      {linkConfigs.map(linkConfig => {
        return (
          <PriorityLink
            key={linkConfig.text}
            linkConfig={linkConfig}
            config={config}
            onMenuClick={onMenuClick}
          />
        );
      })}
    </div>
  ) : (
    ReactDOM.createPortal(
      <div className={css.priorityLinkWrapper} {...styleWrapper} ref={containerRef}>
        {linkConfigs.map(linkConfig => {
          return (
            <PriorityLink
              key={linkConfig.text}
              linkConfig={linkConfig}
              config={config}
              onMenuClick={onMenuClick}
            />
          );
        })}
      </div>,
      document.body
    )
  );
};

export default PriorityLinks;
