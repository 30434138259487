/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import sortBy from 'lodash/sortBy';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';
import IconPlus from '../../../SearchPage/IconPlus/IconPlus';
import reverse from 'lodash/reverse';

import css from './TopbarMobileMenu.module.css';

const SUB_MENU_KEYS = ['women', 'men', 'kids', 'accessories'];

const CustomLinkComponent = ({ linkConfig, currentPage, config }) => {
  const [openFilters, setOpenFilters] = useState([]);
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  const toggleIsOpen = filterId => {
    if (openFilters.includes(filterId)) {
      setOpenFilters(openFilters.filter(o => o !== filterId));
    } else {
      setOpenFilters([...openFilters, filterId]);
    }
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink);
    const searchKey = to?.search?.split('=')?.[1];
    const isValidSubMenuKey = !!searchKey && SUB_MENU_KEYS.includes(searchKey);
    const isValidDesignerKey = text === 'Designer' || text === 'designer';
    const isValidWeddingKey = text === 'Wedding';
    if (isValidSubMenuKey) {
      let subMenuConfigs = config?.listing?.listingFields
        .find(f => f.key === 'subCategory')
        ?.enumOptions?.find(e => e.parentKey === searchKey)?.options;
      subMenuConfigs = sortBy(subMenuConfigs, [
        function(s) {
          return s?.options?.length > 0;
        },
      ]);
      return (
        <div className={classNames(className, css.subMenuLink)}>
          <div
            className={classNames(css.priorityLinkLabel, css.groupLabel)}
            onClick={() => toggleIsOpen(text)}
          >
            <span className={css.label}>{text}</span>
            <span className={css.openSign}>
              <IconPlus isOpen={openFilters.includes(text)} isSelected={false} />
            </span>
          </div>
          {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
            <ul className={classNames(css.subMenu, { [css.isOpen]: openFilters.includes(text) })}>
              {subMenuConfigs.map((s, index) => {
                if (s?.options?.length > 0) {
                  return (
                    <ul
                      className={classNames(css.subMenu, {
                        [css.isOpen]: openFilters.includes(text),
                      })}
                      key={`${text}.${index}`}
                    >
                      {index === 0 ? (
                        <li key={`${s.option}.all`} className={css.allLink}>
                          <NamedLink name={name} params={params} to={to}>
                            <span className={css.priorityLinkLabel}>{`All ${text}`}</span>
                          </NamedLink>
                        </li>
                      ) : null}
                      <li key={`${s.option}`}>
                        <NamedLink
                          name={name}
                          params={params}
                          to={{
                            search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                          }}
                          className={classNames(className, css.subMenuLabel)}
                        >
                          <span className={css.priorityLinkLabel}>{s.label}</span>
                        </NamedLink>
                      </li>
                      {s.options.map((group, index) => {
                        return (
                          <li key={`${group.value}`}>
                            <NamedLink
                              name={name}
                              params={params}
                              to={{
                                search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}&pub_group=${group.value}`,
                              }}
                              className={classNames(className, css.subMenuLink)}
                            >
                              <span className={css.priorityLinkLabel}>{group.label}</span>
                            </NamedLink>
                          </li>
                        );
                      })}
                    </ul>
                  );
                } else {
                  return index === 0 ? (
                    <>
                      <li key={`${s.option}.all`} className={css.allLink}>
                        <NamedLink name={name} params={params} to={to}>
                          <span className={css.priorityLinkLabel}>{`All ${text}`}</span>
                        </NamedLink>
                      </li>
                      <li key={`${s.option}`}>
                        <NamedLink
                          name={name}
                          params={params}
                          to={{
                            search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}`,
                          }}
                          className={classNames(className, css.subMenuLink)}
                        >
                          <span className={css.priorityLinkLabel}>{s.label}</span>
                        </NamedLink>
                      </li>
                    </>
                  ) : (
                    <li key={`${s.option}`}>
                      <NamedLink
                        name={name}
                        params={params}
                        to={{ search: `?pub_listingType=${searchKey}&pub_subCategory=${s.option}` }}
                        className={classNames(className, css.subMenuLink)}
                      >
                        <span className={css.priorityLinkLabel}>{s.label}</span>
                      </NamedLink>
                    </li>
                  );
                }
              })}
            </ul>
          ) : null}
        </div>
      );
    } else if (isValidDesignerKey) {
      const subMenuConfigs = config?.listing?.listingFields.find(f => f.key === 'designer')
        ?.enumOptions;
      return (
        <div className={classNames(className, css.subMenuLink)}>
          <div
            className={classNames(css.priorityLinkLabel, css.groupLabel)}
            onClick={() => toggleIsOpen(text)}
          >
            <span className={css.label}>{text}</span>
            <span className={css.openSign}>
              <IconPlus isOpen={openFilters.includes(text)} isSelected={false} />
            </span>
          </div>

          {!!subMenuConfigs && subMenuConfigs?.length > 0 ? (
            <ul className={classNames(css.subMenu, { [css.isOpen]: openFilters.includes(text) })}>
              {subMenuConfigs.map((s, index) => {
                return index === 0 ? (
                  <>
                    <li key={`${s.option}.all`} className={css.allLink}>
                      <NamedLink name={name} params={params} to={to}>
                        <span className={css.priorityLinkLabel}>{`All ${text}`}</span>
                      </NamedLink>
                    </li>
                    <li key={`${s.option}`}>
                      <NamedLink
                        name={name}
                        params={params}
                        to={{ search: `?pub_designer=${s.option}` }}
                        className={classNames(className, css.subMenuLink)}
                      >
                        <span className={css.priorityLinkLabel}>{s.label}</span>
                      </NamedLink>
                    </li>
                  </>
                ) : (
                  <li key={`${s.option}`}>
                    <NamedLink
                      name={name}
                      params={params}
                      to={{ search: `?pub_designer=${s.option}` }}
                      className={classNames(className, css.subMenuLink)}
                    >
                      <span className={css.priorityLinkLabel}>{s.label}</span>
                    </NamedLink>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      );
    } else if (isValidWeddingKey) {
      const subMenuConfigs = config?.listing?.listingFields.find(f => f.key === 'wedding')
        ?.enumOptions;
      return (
        <div className={classNames(className, css.subMenuLink)}>
          <div
            className={classNames(css.priorityLinkLabel, css.groupLabel)}
            onClick={() => toggleIsOpen(text)}
          >
            <span className={css.label}>{text}</span>
            <span className={css.openSign}>
              <IconPlus isOpen={openFilters.includes(text)} isSelected={false} />
            </span>
          </div>

          <ul className={classNames(css.subMenu, { [css.isOpen]: openFilters.includes(text) })}>
            <li key="wedding.all" className={css.allLink}>
              <NamedLink name={name} params={params} to={{ search: `?pub_bridalGroomOutfit=Yes` }}>
                <span className={css.priorityLinkLabel}>{`All ${text}`}</span>
              </NamedLink>
            </li>

            <li>
              <NamedLink
                name={name}
                params={params}
                to={{ search: `?pub_listingType=men&pub_bridalGroomOutfit=Yes` }}
                className={classNames(className, css.subMenuLink)}
              >
                <span className={css.priorityLinkLabel}>
                  <FormattedMessage id="TopbarDesktop.groom" />
                </span>
              </NamedLink>
            </li>
            <li>
              <NamedLink
                name={name}
                params={params}
                to={{ search: `?pub_listingType=women&pub_bridalGroomOutfit=Yes` }}
                className={classNames(className, css.subMenuLink)}
              >
                <span className={css.priorityLinkLabel}>
                  <FormattedMessage id="TopbarDesktop.bridal" />
                </span>
              </NamedLink>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <NamedLink name={name} params={params} to={to} className={className}>
          {text}
        </NamedLink>
      );
    }
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    config,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const extraLinks = reverse(customLinks).map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
        config={config}
      />
    );
  });

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          <div className={css.customLinksWrapper}>{extraLinks}</div>

          <div className={css.spacer} />
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
        </div>
        <div className={css.customLinksWrapper}>{extraLinks}</div>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
